import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './ProfileInfo.scss';
import VerificationBadge from '../VerificationBadge/VerificationBadge';
import Button from '../Button/Button';
import VisibleIf from '../VisibleIf/VisibleIf';
import InnsamlingskontrollStatus from '../Innsamlingskontroll/InnsamlingskontrollStatus';
import PhoneNumberLink from '../User/PhoneNumberLink';

class ProfileInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
        };
    }

    shouldDisplayWarning({ projectSummary, otherUserProfiles, isUnder15, projectCountWithMoneyLastYear }) {
        if (!projectSummary) {
            return false;
        }
        return (
            projectSummary.numberOfProjectsDeleted >= 4 ||
            projectSummary.numberOfProjectsWithNegativeRating >= 1 ||
            projectSummary.numberOfReports >= 2 ||
            otherUserProfiles.length > 1 ||
            isUnder15 ||
            projectCountWithMoneyLastYear > 3
        );
    }

    render() {
        let theme = '';
        if (this.props.size) {
            theme = `profile-info-theme-${this.props.size}`;
        }

        const editing = this.state.editing;
        return (
            <div
                className={classnames(
                    'profile-info-container',
                    theme,
                    this.props.innsamlingskontrollStatus?.status === 'obs' ? 'profile-warning' : null
                )}
            >
                <div className="profile-info-image-container">
                    <div className="profile-info-image-wrapper">
                        <img className="profile-info-image" src={this.props.imageSrc} alt="" />
                    </div>
                </div>
                <div className="profile-info-text-container">
                    {editing && (
                        <span>
                            <input
                                type="text"
                                className="edit-inline"
                                id="name-box"
                                ref={(input) => (this.nameInput = input)}
                                defaultValue={this.props.title}
                            />
                            <Button
                                className="edit-button"
                                onClick={(e) => {
                                    this.props.editSaveCallback(this.nameInput.value);
                                    this.setState({ editing: false });
                                }}
                            >
                                Lagre
                            </Button>
                            <Button className="edit-button" onClick={(e) => this.setState({ editing: false })}>
                                Avbryt
                            </Button>
                        </span>
                    )}
                    {!editing && (
                        <span className="profile-info-with-edit-button">
                            {this.props.favoriteComponent}{' '}
                            <VisibleIf isVisible={!!this.props.title}>
                                <div className="profile-info-title">
                                    <VerificationBadge
                                        verified={this.props.verified}
                                        partiallyVerified={this.props.partiallyVerified}
                                    />
                                    <InnsamlingskontrollStatus status={this.props.innsamlingskontrollStatus} />

                                    {this.props.title}
                                </div>
                            </VisibleIf>
                            {this.props.editable && (
                                <Button className="start-edit-button" onClick={(e) => this.setState({ editing: true })}>
                                    Endre navn
                                </Button>
                            )}
                        </span>
                    )}
                    {!this.props.title && this.props.titlePlaceholder ? this.props.titlePlaceholder : null}
                    <div className="profile-info-subtitle">{this.props.identification}</div>
                    <div className="profile-info-subtitle">{this.props.accountOwnership}</div>
                </div>
                {this.props.invitationName && (
                    <div className={'profile-info-invitation'}>
                        <div className="profile-info-subtitle">Invitert: {this.props.invitationName}</div>
                        <div className="profile-info-subtitle">
                            Tlf: <PhoneNumberLink phoneNumber={this.props.invitationPhoneNumber} isButton />
                        </div>
                    </div>
                )}
                {this.shouldDisplayWarning({
                    projectSummary: this.props.projectSummary,
                    otherUserProfiles: this.props.otherUserProfiles,
                    isUnder15: this.props.isUnder15,
                    projectCountWithMoneyLastYear: this.props.projectCountWithMoneyLastYear,
                }) && (
                    <div className={'profile-info-warning'}>
                        {this.props.projectCountWithMoneyLastYear >= 3 && (
                            <span>{this.props.projectCountWithMoneyLastYear} prosjekter &gt; 1000kr (1 år)</span>
                        )}
                        {this.props.projectSummary?.numberOfReports > 0 && (
                            <span>{this.props.projectSummary.numberOfReports} rapportering</span>
                        )}
                        {this.props.projectSummary?.numberOfProjectsWithNegativeRating > 0 && (
                            <span>
                                {this.props.projectSummary.numberOfProjectsWithNegativeRating} spleiser med m/ negativ
                                rating
                            </span>
                        )}
                        {this.props.projectSummary?.numberOfProjectsDeleted > 0 && (
                            <span>{this.props.projectSummary.numberOfProjectsDeleted} spleiser slettet</span>
                        )}
                        {this.props.otherUserProfiles.length - 1 > 0 && (
                            <span>{this.props.otherUserProfiles.length - 1} andre brukere</span>
                        )}
                        {this.props.isUnder15 && <span>Er under 15</span>}
                    </div>
                )}
            </div>
        );
    }
}

ProfileInfo.propTypes = {
    size: PropTypes.string,
    imageSrc: PropTypes.string,
    title: PropTypes.string,
    titlePlaceholder: PropTypes.string,
    verified: PropTypes.bool,
    innsamlingskontrollStatus: PropTypes.object,
    identification: PropTypes.string,
    isUnder15: PropTypes.bool,
    accountOwnership: PropTypes.string,
    contact: PropTypes.string,
    editable: PropTypes.bool,
    editSaveCallback: PropTypes.func,
    otherUserProfiles: PropTypes.array,
    projectCountWithMoneyLastYear: PropTypes.number,
};

export default ProfileInfo;
